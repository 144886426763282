import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Routes} from '../routes';
import Utils from '../services/Utils';

// pages
import DashboardOverview from './Orders';
import Settings from './Settings';
import Vehicles from './Vehicles';
import Packages from './Packages';
import Surcharges from './Surcharges';
import Options from './Options';
import DiscountCodes from './DiscountCodes';
import HtmlIntegration from './HtmlIntegration';
import Signin from './login/Signin';
import ForgotPassword from './login/ForgotPassword';
import ResetPassword from './login/ResetPassword';
import NotFoundPage from './login/NotFound';
import Booking from './booking/Booking';
import PaymentSuccess from './booking/PaymentSuccess';
import PaymentCancel from './booking/PaymentCancel';
import BookingConfirmed from './booking/BookingConfirmed';
import BookingCanceled from './booking/BookingCanceled';
import MonthlySubscription from './subscription/MonthlySubscription';
import AnnualSubscription from './subscription/AnnualSubscription';
import LifetimeSubscription from './subscription/LifetimeSubscription';
import SubscriptionSuccess from './subscription/SubscriptionSuccess';
import LifetimeSuccess from './subscription/LifetimeSuccess';

// components
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Preloader from '../components/Preloader';

import {createIntl, createIntlCache, IntlProvider} from 'react-intl';

import messages_fr from '../locales/fr.json';
import messages_en from '../locales/en.json';
import {ReactNotifications} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import '../index.css';

// Translations
const messages = {
  'fr': messages_fr,
  'en': messages_en
};
const language = Utils.getLanguage();
const cache = createIntlCache(); // This is optional but highly recommended since it prevents memory leak
const intl = createIntl({
  locale: language,
  messages: messages[language],
  defaultLocale: 'fr'
}, cache);

const RouteWithLoader = ({component: Component, ...rest}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true}/> <Component {...rest} {...props} /> </>)}/>
  );
};

const RouteWithSidebar = ({component: Component, ...rest}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true}/>
        <Sidebar intl={intl}/>

        <main className="content">
          <Navbar intl={intl}/>
          <Component {...rest} {...props} />
          {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings}/>*/}
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <IntlProvider locale={language} messages={messages[language]} defaultLocale="fr">
    <ReactNotifications/>
    <Switch>
      <RouteWithLoader exact path={Routes.Root.path} component={Signin} intl={intl}/>
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} intl={intl}/>
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} intl={intl}/>
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} intl={intl}/>
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage}/>
      <RouteWithLoader exact path={Routes.Booking.path} component={Booking} intl={intl}/>
      <RouteWithLoader exact path={Routes.PaymentSuccess.path} component={PaymentSuccess}/>
      <RouteWithLoader exact path={Routes.PaymentCancel.path} component={PaymentCancel}/>
      <RouteWithLoader exact path={Routes.BookingConfirmed.path} component={BookingConfirmed} intl={intl}/>
      <RouteWithLoader exact path={Routes.BookingCanceled.path} component={BookingCanceled} intl={intl}/>
      <RouteWithLoader exact path={Routes.MonthlySubscription.path} component={MonthlySubscription}/>
      <RouteWithLoader exact path={Routes.AnnualSubscription.path} component={AnnualSubscription}/>
      <RouteWithLoader exact path={Routes.LifetimeSubscription.path} component={LifetimeSubscription}/>
      <RouteWithLoader exact path={Routes.SubscriptionSuccess.path} component={SubscriptionSuccess}/>
      <RouteWithLoader exact path={Routes.LifetimeSuccess.path} component={LifetimeSuccess}/>

      {/* pages */}
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} intl={intl}/>
      <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} intl={intl}/>
      <RouteWithSidebar exact path={Routes.Vehicles.path} component={Vehicles} intl={intl}/>
      <RouteWithSidebar exact path={Routes.Packages.path} component={Packages} intl={intl}/>
      <RouteWithSidebar exact path={Routes.Surcharges.path} component={Surcharges} intl={intl}/>
      <RouteWithSidebar exact path={Routes.Options.path} component={Options} intl={intl}/>
      <RouteWithSidebar exact path={Routes.DiscountCodes.path} component={DiscountCodes} intl={intl}/>
      <RouteWithSidebar exact path={Routes.HtmlIntegration.path} component={HtmlIntegration} intl={intl}/>

      <Redirect to={Routes.NotFound.path}/>
    </Switch>
  </IntlProvider>
);
